<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/5 w-full">
        <label>Attachment
          <small style="color: red">(only: jpg, jpeg, pdf, png, video)</small>
        </label>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <div class="vx-col w-1/2">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            multiple="multiple"
            accept=".jpg, .jpeg, .png, .pdf, .mp4, .avi, .mkv, .3gp, .m4v, .wmv, .mov"
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
        </div>
      </div>
    </div>

    <vs-divider style="width: 50%; margin-left: 10%">List Attachment</vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 50%; margin-left: 10%">
      <table class="vs-table vs-table--tbody-table">
        <template>
          <tr class="tr-values vs-table--tr tr-table-state-null selected" v-for="(tr, i) in fileAttachment" :key="tr.NameFile">
            <td class="td vs-table--td">{{ tr.NameFile }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show">
                <vs-button type="line" icon-pack="feather" icon="icon-eye" @click.stop="handleShowAttachment(tr)" />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button type="line" icon-pack="feather" icon="icon-trash" @click.stop="handleDeleteAttachment(i)" />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileAttachment: this.value,
    }
  },
  methods: {
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
        this.$emit('input', this.fileAttachment);
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      if (file.FileUrl) {
        window.open(file.FileUrl, "_blank").focus();
      } else {
        let objectURL = URL.createObjectURL(file.File);
        window.open(objectURL, "_blank").focus();
      }
    },
    handleDeleteAttachment(index) {
      this.fileAttachment = this.fileAttachment.filter((r, i) => i !== index);
      this.$emit('input', this.fileAttachment);
    },
  },
  watch: {
    value(newVal) {
      this.fileAttachment = newVal;
    }
  }
}
</script>
