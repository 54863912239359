<template>
  <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Batch ED'">
    <div class="vx-row mb-6">
      <vs-button
          class="ml-4 mt-2"
          color="danger"
          icon-pack="feather"
          icon="icon-arrow-left"
          @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <div class="flex md:flex-row">
      <div class="w-full pr-6 md:basis-1/2">
        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>B-ED ID</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="code" disabled readonly></vs-input>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse Code</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <Warehouse
                v-model="selected_warehouse"
                @selected="handleWarehouseSelected"
            ></Warehouse>
            <span class="text-danger text-sm" v-show="errors.has('Warehouse')">{{ errors.first("Warehouse") }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Counting Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <Date
                @selected="(val) => { this.countingDate = val }"
            ></Date>
            <span class="text-danger text-sm" v-show="errors.has('Counting Date')">{{
                errors.first("Counting Date")
              }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Note</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-textarea v-model="notes" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('Notes')">{{ errors.first("Notes") }}</span>
          </div>
        </div>
      </div>

      <div class="w-full md:basis-1/2">
        <Attachment
            v-model="fileAttachment"
        ></Attachment>
        <span class="text-danger text-sm" v-show="errors.has('fileAttachment')">{{
            errors.first("fileAttachment")
          }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <table width="100%" class="m-3 vs-table vs-table--tbody-table">
        <thead class="vs-table--thead">
        <tr>
          <th style="width: 8.5%;">SKU</th>
          <th style="width: 12.5%;">SKU Description</th>
          <th style="width: 12.5%;">Warehouse Area</th>
          <th style="width: 6%;">Type</th>
          <th style="width: 8.5%;">Batch External</th>
          <th style="width: 6%;">ED</th>
          <th style="width: 6%;">HU</th>
          <th style="width: 6%;">Qty</th>
          <th style="width: 6%;">New Batch External</th>
          <th style="width: 6%;">New ED</th>
          <th style="width: 2.5%;">Add Line</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(line, index) in lines" :key="index" style="padding-bottom: 5px">
          <td class="td vs-table--td" style="padding: 5px">
            <multiselect
                :allowEmpty="false"
                :options="line.option_sku"
                v-model="line.selected_sku"
                :select-label="''"
                :deselect-label="''"
                :label="'label'"
                :track-by="'label'"
                :placeholder="'Select SKU'"
            />
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input class="w-full bg-grey-light" :value="line.selected_sku ? line.selected_sku.item_name : ''"
                      disabled readonly></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <multiselect
              :allowEmpty="false"
              :options="line.option_warehouse_area"
              v-model="line.selected_warehouse_area"
              :select-label="''"
              :deselect-label="''"
              :label="'label'"
              :track-by="'label'"
              :placeholder="'Select Warehouse Area'"
              @input="fetchBatch(selected_warehouse.id, line.selected_sku.sku_code, line.selected_warehouse_area.id, line)"
            />
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input
                :value="line.selected_warehouse_area ? line.selected_warehouse_area.type : ''"
                disabled readonly
                class="w-full bg-grey-light"
            ></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <multiselect
              :filterable="true"
              :allowEmpty="false"
              :options="line.option_batch"
              v-model="line.selected_batch"
              :select-label="''"
              :deselect-label="''"
              :label="'label'"
              :track-by="'value'"
            :placeholder="'Select Batch'"
            />
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input class="w-full bg-grey-light" :value="line.selected_batch ? line.selected_batch.expired_date: ''"
                      disabled readonly></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input class="w-full bg-grey-light" :value="line.selected_batch ? line.selected_batch.units: ''" disabled
                      readonly></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input class="w-full bg-grey-light" :value="line.selected_batch ? line.selected_batch.qty: ''" disabled
                      readonly></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input
                v-model="line.new_batch"
                placeholder="Enter New Batch"
                class="w-full bg-grey-light"
            ></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input type="date" class="w-full" v-model="line.new_ed" name="new_ed"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <div class="vx-input-group flex">
              <vs-button
                  v-if="lines.length > 1"
                  @click.stop="removeRow(index)"
                  size="small"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-trash"
                  style="margin-right: 5px"
                  title="Remove Row"
              />
              <vs-button
                  v-if="index === lines.length - 1"
                  @click.stop="addRow()"
                  size="small"
                  color="success"
                  icon-pack="feather"
                  icon="icon-plus"
                  title="Add Row"
              />
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <br>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
          <vs-button class="mr-3 mb-2" @click="handleSave">Save</vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import Attachment from "./components/Attachment.vue";
import Date from "./components/Date.vue";
import Warehouse from "./components/Warehouse.vue";
import moment from "moment/moment";

export default {
  components: {Warehouse, Date, Attachment},
  data() {
    return {
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      code: "Auto Generated",
      selected_warehouse: null,
      countingDate: null,
      notes: null,
      fileAttachment: [],
      lines: [
        {
          selected_sku: null,
          option_sku: [],
          selected_warehouse_area: null,
          option_warehouse_area: [],
          selected_area_types: null,
          selected_batch: null,
          option_batch: [],
          new_batch: null,
          new_ed: null,
        }
      ],
    };
  },
  methods: {
    handleWarehouseSelected(val) {
      this.selected_warehouse = val;
      this.fetchItems(val.id);
      this.fetchWarehouseArea(val.id);
    },
    fetchItems(warehouseID) {
      const baseUrl = `/api/wms/v1/master/item-by-warehouse`;
      this.$http
          .get(baseUrl, {
            params: {
              order: "item_name",
              sort: "asc",
              warehouse_id: warehouseID,
            }
          })
          .then(resp => {
            if (resp.code === 200) {
              resp.data.records.map(function (x) {
                x.label = x.sku_code;
                return x;
              });

              this.lines.forEach(line => {
                line.option_sku = resp.data.records;
              });
            } else {
              this.lines.forEach(line => {
                line.option_sku = [];
              });
            }
          });
    },
    fetchWarehouseArea(warehouseID) {
      const baseUrl = `/api/wms/v1/master/warehouse-area-by-warehouse`;
      this.$http
          .get(baseUrl, {
            params: {
              order: "name",
              sort: "asc",
              warehouse_id: warehouseID,
            }
          })
          .then(resp => {
            if (resp.code === 200) {
              resp.data.records.map(function (x) {
                x.label = x.code + "-" + x.name
                return x;
              });

              this.lines.forEach(line => {
                line.option_warehouse_area = resp.data.records;
              });
            } else {
              this.lines.forEach(line => {
                line.option_warehouse_area = [];
              });
            }
          });
    },
    fetchBatch(warehouseID, skuCode, warehouseAreaID, line) {
      const baseUrl = `/api/wms/v1/master/get-batch-by-item-warehouse-warehouse-area`;
      this.$http
        .get(baseUrl, {
          params: {
            order: "batch",
            sort: "asc",
            warehouse_id: warehouseID,
            sku_code: skuCode,
            warehouse_area_id: warehouseAreaID
          }
        })
        .then(resp => {
          if (resp.code === 200) {
            const batches = resp.data.records
              .filter(x => x.batch_external !== null && x.batch_external !== "")
              .map(x => {
                return {
                  label: x.batch_external,
                  value: `${x.batch_external}-${x.unit}`,
                  expired_date: moment(x.expired_date).format('YYYY-MM-DD'),
                  qty: x.amount,
                  amount_uom: x.amount_uom,
                  units: x.unit,
                  in_time: x.in_time,
                  item_unit_id: x.item_unit_id,
                  batch_external: x.batch_external,
                  batch: x.batch,
                  serial_number: x.serial_number
                };
              });

            line.option_batch = batches;
          } else {
            line.option_batch = [];
          }
        });
    },
    handleBack() {
      this.$vs.loading();
      this.$router.go(-1);
      this.$vs.loading.close();
    },
    removeRow(index) {
      this.lines.splice(index, 1);
    },
    addRow() {
      const newLine = {
        selected_sku: null,
        option_sku: [],
        selected_warehouse_area: null,
        option_warehouse_area: [],
        selected_area_types: null,
        selected_batch: null,
        option_batch: [],
        new_batch: null,
        new_ed: null,
        batch: null,
        serial_number: null,
      };
      this.lines.push(newLine);
      this.fetchItems(this.selected_warehouse.id);
      this.fetchWarehouseArea(this.selected_warehouse.id);
    },
    validateLines() {
      let isValid = true;
      const today = moment().startOf('day');

      this.lines.forEach((line, index) => {
        const sku = line.selected_sku ? line.selected_sku.sku_code : null;
        const batch = line.selected_batch ? line.selected_batch.label : null;
        const newEd = line.new_ed ? moment(line.new_ed).startOf('day') : null;

        if (!sku || !line.selected_warehouse_area || !batch || !line.new_batch || !line.new_ed) {
          this.$vs.notify({
            title: "Error",
            text: "All fields in each line must be filled",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          isValid = false;
        }

        if (sku && batch) {
          this.lines.forEach((innerLine, innerIndex) => {
            if (index !== innerIndex && innerLine.selected_sku && innerLine.selected_sku.sku_code === sku && innerLine.selected_batch && innerLine.selected_batch.label === batch) {
              this.$vs.notify({
                title: "Error",
                text: "Duplicate Batch selected for the same SKU",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              isValid = false;
            }
          });
        }

        if (newEd && newEd.isBefore(today)) {
          this.$vs.notify({
            title: "Error",
            text: "New Expiry Date cannot be a back date",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          isValid = false;
        }
      });

      return isValid;
    },
    handleSave() {
      this.errors.clear();
      this.$validator.validateAll().then((result) => {
        if (!this.selected_warehouse) {
          this.errors.add({
            field: "Warehouse",
            msg: "Warehouse is required",
          });
          result = false;
        }
        if (!this.countingDate) {
          this.errors.add({
            field: "Counting Date",
            msg: "Counting Date Required"
          });
          result = false;
        }
        if (!this.notes) {
          this.errors.add({
            field: "Notes",
            msg: "Note Required"
          });
          result = false;
        }
        if (this.fileAttachment.length === 0) {
          this.errors.add({
            field: "fileAttachment",
            msg: "This Attachment is required",
          });
          result = false;
        }

        if (!this.validateLines()) {
          result = false;
        }

        if (result) {
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    putData() {
      let form = new FormData();
      form.append("warehouse_id", this.selected_warehouse.id);
      form.append("counting_date", moment(this.countingDate).format("YYYY-MM-DD"));
      form.append("note", this.notes);

      // Attachment
      var attachment_id;
      for (let index = 0; index < this.fileAttachment.length; index++) {
        if (typeof this.fileAttachment[index].id === "undefined") {
          attachment_id = 0;
        } else {
          attachment_id = this.fileAttachment[index].id;
        }
        form.append("attachment_id[]", attachment_id);
        form.append("attachments[]", this.fileAttachment[index].File);
      }

      // Lines
      const itemLines = this.lines.map(line => ({
        sku_code: line.selected_sku ? line.selected_sku.sku_code : null,
        warehouse_area_id: line.selected_warehouse_area ? line.selected_warehouse_area.id : null,
        area_type_id: line.selected_warehouse_area ? line.selected_warehouse_area.area_type_id : null,
        batch: line.selected_batch ? line.selected_batch.batch : null,
        expired_date: line.selected_batch ? line.selected_batch.expired_date : null,
        units: line.selected_batch ? line.selected_batch.units : null,
        qty: line.selected_batch ? line.selected_batch.qty : null,
        in_time: line.selected_batch ? line.selected_batch.in_time : null,
        item_unit_id: line.selected_batch ? line.selected_batch.item_unit_id : null,
        new_batch: line.new_batch,
        new_ed: line.new_ed ? moment(line.new_ed).format("YYYY-MM-DD") : null,
        serial_number: line.selected_batch ? line.selected_batch.serial_number : "",
        batch_external: line.selected_batch ? line.selected_batch.label : null,
      }));

      // Append the itemLines array as JSON
      form.append("line", JSON.stringify(itemLines));

      // do put
      this.$vs.loading();
      this.$http.put(`/api/wms/v1/batch-ed/update-v2/${this.id}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((resp) => {
        if (resp.code === 200) {
          this.$vs.notify({
            title: "Success",
            text: "Batch ED has been updated",
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.handleBack();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    postData() {
      let form = new FormData();
      form.append("warehouse_id", this.selected_warehouse.id);
      form.append("counting_date", moment(this.countingDate).format("YYYY-MM-DD"));
      form.append("note", this.notes);

      // Attachment
      for (let index = 0; index < this.fileAttachment.length; index++) {
        form.append("attachments[]", this.fileAttachment[index].File);
      }

      // Lines
      const itemLines = this.lines.map(line => ({
        sku_code: line.selected_sku ? line.selected_sku.sku_code : null,
        warehouse_area_id: line.selected_warehouse_area ? line.selected_warehouse_area.id : null,
        area_type_id: line.selected_warehouse_area ? line.selected_warehouse_area.area_type_id : null,
        batch: line.selected_batch ? line.selected_batch.batch : null,
        expired_date: line.selected_batch ? line.selected_batch.expired_date : null,
        units: line.selected_batch ? line.selected_batch.units : null,
        qty: line.selected_batch ? line.selected_batch.qty : null,
        in_time: line.selected_batch ? line.selected_batch.in_time : null,
        item_unit_id: line.selected_batch ? line.selected_batch.item_unit_id : null,
        new_batch: line.new_batch,
        new_ed: line.new_ed ? moment(line.new_ed).format("YYYY-MM-DD") : null,
        serial_number: line.selected_batch ? line.selected_batch.serial_number : null,
        batch_external: line.selected_batch ? line.selected_batch.label : null,
      }));

      // Append the itemLines array as JSON
      form.append("line", JSON.stringify(itemLines));

      // do post
      this.$vs.loading();
      this.$http.post("/api/wms/v1/batch-ed/create-v2", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((resp) => {
        if (resp.code === 200) {
          this.$vs.notify({
            title: "Success",
            text: "Batch ED has been saved",
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          this.handleBack();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        this.$vs.loading.close();
      });
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/batch-ed/detail/" + this.id).then((resp) => {
        if (resp.code === 200) {
          const data = resp.data.batch_ed;
          this.code = data.Code;
          this.selected_warehouse = {
            id: data.WarehouseID,
            code: data.WarehouseCode,
            name: data.WarehouseName,
            label: `${data.WarehouseCode} - ${data.WarehouseName}`
          };
          this.countingDate = moment(data.CountingDate).format('YYYY-MM-DD');
          this.notes = data.Note;
          this.fileAttachment = resp.data.batch_ed_attach.map(attachment => ({
            id: attachment.id,
            FileUrl: attachment.FileUrl,
            NameFile: attachment.FileName,
          }));
          this.lines = resp.data.batch_ed_line.map(line => ({
            selected_sku: {
              sku_code: line.SkuCode,
              item_name: line.ItemName,
              label: line.SkuCode,
            },
            option_sku: [],
            selected_warehouse_area: {
              id: line.WarehouseAreaID,
              name: line.WarehouseAreaName,
              code: line.WarehouseAreaCode,
              area_type_id: line.AreaTypeID,
              type: line.AreaTypeTypes,
              label: `${line.WarehouseAreaCode} - ${line.WarehouseAreaName}`,
            },
            option_warehouse_area: [],
            selected_batch: {
              label: line.BatchExternal,
              expired_date: moment(line.ExpiredDate).format('YYYY-MM-DD'),
              units: line.Unit,
              qty: line.Qty,
              in_time: line.InTime,
              item_unit_id: line.ItemUnitID,
              serial_number: line.SerialNumber,
              batch_external: line.BatchExternal,
              batch: line.Batch,
            },
            option_batch: [],
            new_batch: line.NewBatch,
            new_ed: moment(line.NewExpiredDate).format('YYYY-MM-DD')
          }));

          const warehouseId = this.selected_warehouse.id;
          this.fetchItems(warehouseId);
          this.fetchWarehouseArea(warehouseId);
          this.lines.forEach(item => {
            const { selected_sku, selected_warehouse_area } = item;
            if (selected_sku && selected_warehouse_area) {
              const skuCode = selected_sku.sku_code;
              const warehouseAreaId = selected_warehouse_area.id;
              this.fetchBatch(warehouseId, skuCode, warehouseAreaId, item);
            }
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        this.$vs.loading.close();
      });
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  watch: {
    lines: {
      handler(newVal) {
        newVal.forEach(line => {
          this.$watch(() => line.selected_sku, (newSku, oldSku) => {
            if (newSku !== oldSku) {
              line.selected_warehouse_area = null;
              line.option_batch = [];
              line.selected_batch = null;
            }
          });
        });
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
